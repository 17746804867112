<nav class="navbar navbar-expand-lg fixed-top navbar-light bg-white">
	<div class="container-fluid">
			<a class="navbar-brand" >
				<img routerLink="" class="logoimg" src="../../assets/images/logo.png" alt="logo">
			</a>
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse bg-white" id="navbarNav">
				<ul class="navbar-nav me-auto bg-white">
					<li class="nav-item">
						<a class="nav-link active" aria-current="page" routerLink=""><img src="../../assets/images/footer/house.png" alt=""> Home</a>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLink="allservices" href="#" id="navbarScrollingDropdown" role="button" data-hover="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
						<img src="../../assets/images/footer/customer-service.png" alt="">	Services
						</a>
						<ul class="dropdown-menu dropdown-menuSS" aria-labelledby="navbarScrollingDropdown">
							<li><a routerLink="open-vehicle-transport" class="dropdown-item"> <img src="../../assets/images/final icons/Open Vehicle Transport.png" alt="" class="service-img"> Open Vehicle Transport</a></li>
							<li><a routerLink="enclosed-autotransport" class="dropdown-item"><img src="../../assets/images/final icons/enclosed-auto transport.png" alt="" class="service-img"> Enclosed Vehicle Transport</a></li>
							<li><a routerLink="Door-to-door-auto-transport" class="dropdown-item"> <img src="../../assets/images/final icons/Door-to-door-auto-transport.png" alt="" class="service-img"> Door To Door Auto Transport</a></li>
							<li><a routerLink="car-shipping-service" class="dropdown-item"><img src="../../assets/images/final icons/car shipping services.png" alt="" class="service-img"> Car Shipping Service</a></li>
							<li><a routerLink="Snowbird-auto-transport" class="dropdown-item"><img src="../../assets/images/final icons/Snowbird-auto-transport.png" alt="" class="service-img">Snowbird Auto Transport</a></li>
							<li><a routerLink="multiple-vehicle-quote" class="dropdown-item"><img src="../../assets/images/final icons/Multiple Vehicles Transport.png" alt="" class="service-img">Multiple Vehicles Transport</a></li>
							<li><a routerLink="vintage-and-classical-car-shipping" class="dropdown-item"><img src="../../assets/images/final icons/vintage-and-classica.png" alt="" class="service-img">Vintage & Classic Vehicle Transport</a></li>
							<li><a routerLink="vehicle-shipping" class="dropdown-item"><img src="../../assets/images/final icons/Vehicle Shipping Across Country.png" alt="" class="service-img">Vehicle Shipping Across Country</a></li>
							<li><a routerLink="car-shipping-cost" class="dropdown-item"><img src="../../assets/images/final icons/car-shipping-cost.png" alt="" class="service-img"> Vehicle Shipping Cost</a></li>
							<li><a routerLink="boat-transportation" class="dropdown-item"><img src="../../assets/images/final icons/boat transportation services.png" alt="" class="service-img"> Boat Transportation Services</a></li>
							<li><a routerLink="Motorcycle-Shipping" class="dropdown-item"><img src="../../assets/images/final icons/Motorcycle-Shipping.png" alt="" class="service-img"> Motorcycle Transportation Services</a></li>
							<li><a routerLink="inoperable-car-transport" class="dropdown-item"><img src="../../assets/images/final icons/inoperable-car-transport.png" alt="" class="service-img"> InOperable Auto Transport</a></li>
						</ul>
					</li>
					<!-- <li class="nav-item">
						<a routerLink="payment" class="nav-link"><img src="../../assets/images/footer/pricing@25px.png" alt=""> Payment Via CC</a>
					</li> -->
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<img src="../../assets/images/footer/buildings.png" alt="">	Company
						</a>
						<ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
							<li><a routerLink="how-it-work" class="dropdown-item"><img src="../../assets/images/footer/how it works@25px.png" alt=""> How it works?</a></li>
							<li><a routerLink="why-us" class="dropdown-item"><img src="../../assets/images/footer/what is AQ Movers@25px.png" alt=""> Why Us?</a></li>
							<li><a routerLink="contact_us" class="dropdown-item"><img src="../../assets/images/footer/contact us50px.png" alt=""> Contact Us</a></li>
							<li><a routerLink="faq" class="dropdown-item"><img src="../../assets/images/footer/faq.png" alt="">FAQ</a></li>
							<li><a routerLink="Blogs" class="dropdown-item"><img src="../../assets/images/footer/blogging.png" alt="">Blogs</a></li>
						</ul>
					</li>
					<li class="nav-item"><a routerLink="about_us" class="nav-link"><img src="../../assets/images/footer/who are we@25px.png" alt=""> About Us</a></li>
					<!-- <li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<img src="../../assets/images/footer/agreement.png" alt="">	Agreements
						</a>
						<ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
							<li><a routerLink="agreement" class="dropdown-item">Shipper’s Agreement</a></li>
							<li><a routerLink="carrier_agreement" class="dropdown-item">Carrier’s Agreement</a></li>
						</ul>
					</li> -->
				</ul>
				<span class="navbar-text">
					<div class="contact">
						<a href="tel:+1 (806) 414-0249‬" class="btn Call"> <i class="fa fa-phone"></i>(806) 414-0249‬
						</a>
					</div>
				</span>
			</div>
	</div>
</nav>